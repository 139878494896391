<template>
  <div class="mt-16 bg-white">
    <div class="p-16 bg-gray-100 sombra-1">
      <h1 class="font-bold text-5xl sm:text-6xl">Galpones</h1>
      <div>
        <p>
          Posee un sistema constructivo de placas de hormigón armado, abulonadas
          entre sí, las mismas miden 1,80m de ancho por una altura de 3m, que
          con el anexo de otra losa o el agregado de chapa se puede acrecentar
          la misma respondiendo a la necesidad de cada proyecto.
        </p>
        <p>
          Este sistema constructivo fue racionalmente estudiado permitiéndose
          ser aplicados a múltiples proyectos: como cerramiento de tinglados,
          plantas industriales, depósitos, salones de venta, galpones.
        </p>
        <p>
          No requiere ningún tipo de terminación superficial, manteniéndose
          inalterable a través del tiempo.
        </p>
        <p>
          Brindamos presupuestos y cálculos de estructuras para cada caso en
          particular, confeccionados por profesionales de la empresa.
        </p>
      </div>
    </div>

    <div class="py-16 px-1 bg-gray-300 sombra-2">
      <h2
        class="font-bold text-4xl sm:text-5xl w-full h-full text-center text-red-700"
      >
        Construcción
      </h2>
      <carousel
        :per-page="perPagePhotos"
        centerMode
        loop
        touchDrag
        paginationColor="white"
      >
        <slide
          v-for="(img, i) in construccion"
          :key="i"
          class="flex justify-center"
        >
          <img
            :style="breakpoints.is != 'sm' ? 'max-height: 500px' : ''"
            v-lazy="img"
          />
        </slide>
      </carousel>
    </div>

    <div class="py-16 px-1 bg-gray-200 sombra-1">
      <h2
        class="font-bold text-4xl sm:text-5xl w-full h-full text-center text-blue-700"
      >
        Terminados
      </h2>
      <carousel
        :per-page="perPagePhotos"
        centerMode
        loop
        touchDrag
        paginationColor="rgb(220 220 220)"
      >
        <slide
          v-for="(img, i) in terminados"
          :key="i"
          class="flex justify-center"
        >
          <img
            :style="breakpoints.is != 'sm' ? 'max-height: 500px' : ''"
            v-lazy="img"
          />
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import breakpoints from "@/plugins/breakpoints";

export default {
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      terminados: [
        require("@/assets/img/full/Galpon/terminados/img (1).jpg"),
        require("@/assets/img/full/Galpon/terminados/img (2).jpg"),
        require("@/assets/img/full/Galpon/terminados/img (3).jpg"),
        require("@/assets/img/full/Galpon/terminados/img (4).jpg"),
        require("@/assets/img/full/Galpon/terminados/img (5).jpg"),
        require("@/assets/img/full/Galpon/terminados/img (6).jpg"),
        require("@/assets/img/full/Galpon/terminados/img (7).jpg"),
        require("@/assets/img/full/Galpon/terminados/img (8).jpg"),
        require("@/assets/img/full/Galpon/terminados/img (9).jpg")
      ],
      construccion: [
        require("@/assets/img/full/Galpon/construccion/img (1).jpg"),
        require("@/assets/img/full/Galpon/construccion/img (2).jpg"),
        require("@/assets/img/full/Galpon/construccion/img (4).jpg"),
        require("@/assets/img/full/Galpon/construccion/img (7).jpg"),
        require("@/assets/img/full/Galpon/construccion/img (8).jpg")
      ],

      breakpoints: breakpoints
    };
  },
  computed: {
    perPagePhotos() {
      if (breakpoints.is == "lg" || breakpoints.is == "xl") return 2;
      else return 1;
    }
  }
};
</script>
